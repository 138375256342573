import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/layouts/layout.js";
import fileStructure from "../../../images/file-structure.png";
import { BlogImage } from "../../../components/BlogComponents";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`It's been a while since I posted `}<a parentName="p" {...{
        "href": "/blog/dfinity/getting-started-frontend"
      }}>{`my first writeup`}</a>{` on getting started on the Internet Computer. About three months, actually. Could I have written up a few more posts in that time on how I was figuring out the ins and outs of building IC web apps? Sure I could. However, I was convinced that nothing I wrote would ultimately be what I wanted to recommend to the world in general, because I could see a game-changing new technology on the horizon. Thanks to the efforts of the Dfinity Developer Experience team, that new experience is what I'm happy to announce to you today!`}</p>
    <p>{`My official announcement is posted here: `}<a parentName="p" {...{
        "href": "https://forum.dfinity.org/t/preview-improved-asset-canisters/2387"
      }}>{`Dfinity Forum`}</a>{` - it covers some of the same things I'm saying here, but is still worth a read!`}</p>
    <p>{`Also check out this writeup from another SDK team member on the architecture of our solution: `}<a parentName="p" {...{
        "href": "https://medium.com/dfinity/a-technical-overview-of-the-internet-computer-f57c62abc20f"
      }}>{`Technical Overview`}</a>{`.`}</p>
    <h2 {...{
      "id": "announcing-improved-asset-canisters"
    }}><a parentName="h2" {...{
        "href": "#announcing-improved-asset-canisters"
      }}>{`Announcing Improved Asset Canisters`}</a></h2>
    <p>{`When you first learn how to build a website, the building blocks all start in a pretty similar way. First, you create an `}<inlineCode parentName="p">{`index.html`}</inlineCode>{` file, and fill it out with some content. Next, you style it by creating a `}<inlineCode parentName="p">{`styles.css`}</inlineCode>{` file, make some nice designs, and link it in your html file. Finally, if you have some fancy functionality you'd like to add, you attach some JavaScript via an `}<inlineCode parentName="p">{`index.js`}</inlineCode>{` file and call it a day.`}</p>
    <p>{`Until now, building on the IC, we provided you with one `}<inlineCode parentName="p">{`index.html`}</inlineCode>{` file that was pre-programmed to do a bunch of fancy things for you. It would load the page, create an anonymous identity using our cryptographically secure messaging library, and request a single bundle of JavaScript. That bundle had a 2MB limit, and could take several seconds before loading your custom content. We only supported that one file being requested, so any HTML, CSS, JavaScript, or images you wanted on the page all had to be crammed into that bundle, which created many significant constraints. While it was still impressive to be able to serve web applications off a blockchain, we knew we could do better, and we wanted to bring things back to that simple pattern of requesting files that the modern web is built on.`}</p>
    <BlogImage src={fileStructure} mdxType="BlogImage">
  A familiar view of static assets in a VSCode editor
    </BlogImage>
    <p>{`With the upgraded asset canister, we are giving engineers the freedom to use their own HTML, images, and whatever arbitrary content they want their canister to provide over standard HTTP requests.`}</p>
    <p>{`We have unblocked using whatever JavaScript bundler you like, any library or framework you choose, and are no longer even assuming that you'll want to communicate with a smart contract on the IC. An asset canister can now be as unopinionated as a hosting target as a generic Nginx or Express server.`}</p>
    <h2 {...{
      "id": "deprecations"
    }}><a parentName="h2" {...{
        "href": "#deprecations"
      }}>{`Deprecations`}</a></h2>
    <p>{`In order to make this possible, we had to walk back the work we were doing to set you up with an anonymous identity. This means that `}<inlineCode parentName="p">{`window.ic`}</inlineCode>{` is going away, and in order to interface with the Internet Computer, you will need to import our code from our npm package `}<inlineCode parentName="p">{`@dfinity/agent`}</inlineCode>{`. Agent will provide you with the tools to make requests to Internet Computer canisters, and we have another package `}<inlineCode parentName="p">{`@dfinity/authentication`}</inlineCode>{` that will give you the tools to create a cryptographically secure identity that can be managed using the new browser Web Authentication API.`}</p>
    <p>{`We provide you with the basics of how to get started with the new `}<inlineCode parentName="p">{`dfx new`}</inlineCode>{` template. Check out the `}<a parentName="p" {...{
        "href": "https://www.notion.so/Announcing-Improved-Asset-Canisters-7b5815f6ca46461dae4b7f22501f4e39"
      }}>{`writeup`}</a>{` for some more details about downloading and getting set up with the preview build of DFX.`}</p>
    <p>{`It's been a busy week, so that's about all I'll get to today, but soon I'll follow up with my revised guide on getting started doing frontend development on the IC.`}</p>
    <h2 {...{
      "id": "postscript"
    }}><a parentName="h2" {...{
        "href": "#postscript"
      }}>{`Postscript`}</a></h2>
    <p>{`I almost forgot! I'm taking ownership of those NPM packages now. There are lots of improvments to look forward to, but my focus will be on improving our documentation for now.`}</p>
    <p>{`The new docs are hosted as static websites on the IC, which I think is pretty neat.`}</p>
    <p><a parentName="p" {...{
        "href": "https://peacock.dev/agent-docs"
      }}>{`Agent Docs`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://peacock.dev/authentication-docs"
      }}>{`Authentication Docs`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      